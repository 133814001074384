<div class="bg-synth">
    <div class="container-fluid position-relative h-100 d-flex flex-column">
      <div class="row mt-5">
         <!-- back button -->
         <div class="col-12 col-lg-2">
          <span class="btn btn-arrow font-terminal text-blue pa-0">&#60;</span>
          <button type="button" class="btn btn-link font-terminal text-blue px-0"
            routerLink="/main-menu">menu</button>
        </div>
        <!-- page title -->
        <div class="col-12 col-lg-8">
          <h1 class="font-terminal text-uppercase text-blue glitch text-center">MY CUSTOMIZATIONS</h1>
        </div>
      </div>
      <!-- customization list -->
      <div class="row justify-content-center flex-1 mt-5">
        <div class="col-8 col-lg-10">
            <div class="row mt-5 list-item" 
            *ngFor="let game of customizationsGamesList; let i = index"
            [ngClass]="{active: currentItem === i}"
            (mouseover)="currentItem = i">
              <div class="col-12 col-lg-1 d-flex justify-content-center">
                <span class="h3 text-blue font-terminal align-self-center">&#62;&nbsp;</span>
              </div>
              <div class="col-12 col-lg-2">
                <img src="../../assets/img/games/{{game.imagePath}}" class="img-fluid pxl-border border-blue" />
              </div>
              <div class="col-12 col-lg-4">
                <a routerLink="/">
                  <p class="h2 font-terminal text-blue">{{game.name}}</p>
                </a>  
                <p class="h4 font-terminal text-blue mb-0" >{{game.customizations}} customizations</p>
              </div>
              <div class="col-12 col-lg-4 d-flex justify-content-end align-self-center">
                <button type="button" class="btn-retro pxl-border border-orange mr-xl btn-orange" routerLink="/">ADD NEW</button>
                <button type="button" class="btn-retro pxl-border border-pink btn-pink" [routerLink]="game.route">SEE ALL</button>
              </div>
              <div class="col-12 col-lg-1 d-flex justify-content-center">
                  <span class="h3 text-blue font-terminal align-self-center">&nbsp;&#60;</span>
              </div>
            </div>
        </div>
      </div>
      <!-- helper text -->
      <div class="row">
        <div class="col-12 col-lg-6 offset-lg-3 text-center mb-3">
          <p>
            <span class="text-helper px-1">
              <svg width="20" height="20" fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve"><g><g>
                <path fill="#ffffff" d="M240,0C156.091,0.093,88.093,68.091,88,152v176c0,83.947,68.053,152,152,152s152-68.053,152-152V152
                    C391.907,68.091,323.909,0.093,240,0z M256,80v48c0,8.837-7.163,16-16,16s-16-7.163-16-16V80c0-8.837,7.163-16,16-16
                    S256,71.163,256,80z M104,152c0.119-71.926,56.2-131.333,128-135.592v32.728C217.915,52.773,208.055,65.453,208,80v48
                    c0.055,14.547,9.915,27.227,24,30.864V192H104V152z M376,328c-0.084,75.076-60.924,135.916-136,136
                    c-75.076-0.084-135.916-60.924-136-136V208h272V328z M376,192H248v-33.136c14.086-3.637,23.945-16.317,24-30.864V80
                    c-0.055-14.547-9.914-27.227-24-30.864V16.408C319.8,20.667,375.881,80.074,376,152V192z"/>
                </g>
              </g>
              </svg>
            </span>
            <span class="font-terminal text-white text-helper">Select a game. Repair it, play it, share it.</span></p>
        </div>
      </div>
    </div>
  </div>
