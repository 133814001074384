import { Component, Input, OnInit } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-r-action-modal',
  templateUrl: './r-action-modal.component.html',
  styleUrls: ['./r-action-modal.component.scss']
})
export class RActionModalComponent implements OnInit {

  @Input() taskTitle: string;
  @Input() taskDescription: string;
  @Input() taskCategory: string;

  floppyPath: string;
  isRepairMode: boolean;

  constructor(
    private activeModal: NgbActiveModal) { }


  ngOnInit(): void {
    // this.theoryDescription = this.sanitizer.bypassSecurityTrustHtml(this.theoryDescription);
    if (this.taskCategory == "HTML") {
      this.floppyPath = "/assets/img/elements/floppy-orange.png";
    } else if (this.taskCategory == "CSS") {
      this.floppyPath = "/assets/img/elements/floppy-purple.png";
    } else {
      this.floppyPath = "/assets/img/elements/floppy-yellow.png";
    }
  }

  close() {
    this.activeModal.close();
  }
}
