import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-r-action-result-modal',
  templateUrl: './r-action-result-modal.component.html',
  styleUrls: ['./r-action-result-modal.component.scss']
})
export class RActionResultModalComponent implements OnInit {

  @Input() success: boolean;

  constructor(
    private activeModal: NgbActiveModal) { }

  ngOnInit(): void {

  }

  close() {
    this.activeModal.close();
  }
}
