<!-- TODO diana -->
<div class="modal-content pxl-border border-blue p-3">
  <div class="modal-header">
    <img *ngIf="success" src="../../assets/img/website/r-happy.png" class="position-absolute zindex-1 w-20 r-game" />
    <img *ngIf="!success" src="../../assets/img/website/r-sad.png" class="position-absolute zindex-1 w-20 r-game" />
    <h1 class="modal-title mt-0" id="exampleModalLongTitle">{{success ? "Correct!" : "Wrong..."}}</h1>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn-retro btn-orange pxl-border border-orange" (click)="close()">{{success ? "Next" : "Try again"}}</button>
  </div>
</div>
