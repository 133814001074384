<div class="position-absolute w-100 h-100 icons-grid">
    <div class="icons-grid__carrot">
      <img src="../../assets/img/elements/carrot.png" />
    </div>
    <div class="icons-grid__heart">
      <img src="../../assets/img/elements/heart.png"/>
    </div>
    <div class="icons-grid__oneup">
      <img src="../../assets/img/elements/oneup.png" />
    </div>
    <div class="icons-grid__strawberry">
      <img src="../../assets/img/elements/strawberry.png"/>
    </div>
    <div class="icons-grid__star">
      <img src="../../assets/img/elements/star.png" />
    </div>
    <div class="icons-grid__ghost">
      <img src="../../assets/img/elements/ghost.png"/>
    </div>
    <div class="icons-grid__floppy-pink">
      <img src="../../assets/img/elements/floppy-pink.png" />
    </div>
    <div class="icons-grid__floppy-blue">
      <img src="../../assets/img/elements/floppy-blue.png" />
    </div>
    <div class="icons-grid__floppy-yellow">
      <img src="../../assets/img/elements/floppy-yellow.png" />
    </div>
  </div>
