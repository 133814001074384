<!-- design 404 website -->
<section class="h-vh d-flex">
    <app-icons-grid></app-icons-grid>
    <div class="container-fluid d-flex align-items-center justify-content-center position-relative zindex-1">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
            <img src="../../assets/img/website/r-error.png" class="img-fluid w-50 mb-4"/>
                <p class="h3 font-terminal text-dark-purple text-center mb-4">Ooops, it seems that the page you're looking for does not exist :(</p>
                <button routerLink="/" class="btn-retro pxl-border border-purple btn-dark-purple">BACK TO WEBSITE</button>
            </div>
        </div>
    </div>
</section>

<!-- design 404 game -->
<!-- <section class="h-vh d-flex bg-synth">
    <div class="container-fluid d-flex align-items-center justify-content-center">
        <div class="row">
            <div class="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
            <img src="../../assets/img/website/r-error.png" class="img-fluid w-50 mb-4"/>
                <p class="h3 font-terminal text-blue text-center mb-4">Ooops, it seems that the page you're looking for does not exist :(</p>
                <button routerLink="/main-menu" class="btn-retro pxl-border border-blue btn-blue">BACK TO MENU</button>
            </div>
        </div>
    </div>
</section> -->
 
 