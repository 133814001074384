<div class="modal-content pxl-border border-blue p-3">
  <div class="modal-header pb-0">
    <img src="../../assets/img/website/r-happy.png" class="position-absolute zindex-1 w-20 r-game" />
    <h1 class="modal-title mt-0" id="exampleModalLongTitle">{{taskTitle}}</h1>
  </div>

  <div class="modal-body pt-0">
    <!-- <p>{{description}}</p>
    <p>{{theoryTitle}}</p>
    <div class="theory-description" [innerHtml]="theoryDescription"></div> -->
    <p *ngIf="taskCategory">
      <img src="{{floppyPath}}" class="floppy__modal pr-2">
      <span class="h5 font-terminal text-blue px-2">Repair the {{taskCategory}} Floppy</span>
    </p>
    <p class="font-terminal text-blue mt-5">{{taskDescription}}</p>
    <!-- <p style="white-space: pre-wrap;">{{taskDescription}}</p> -->
  </div>

  <div class="modal-footer">
    <button type="button" class="btn-retro btn-orange pxl-border border-orange" (click)="close()">{{isRepairMode ? "Repair" : "Got it"}}</button>
  </div>
</div>
