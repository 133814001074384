import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { appInitializer } from './_helpers/app.initializer';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { AccountService } from './_services/account.service';
import { CustomizationsListComponent } from './customizations-list/customizations-list.component';
import { SettingsComponent } from './settings/settings.component';
import { IconsGridComponent } from './_shared/icons-grid/icons-grid.component';
import { RActionModalComponent } from './_shared/r-action-modal/r-action-modal.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { PreviewGameComponent } from './preview-game/preview-game.component';
import { PreviewGridComponent } from './_shared/preview-grid/preview-grid.component';
import { RActionResultModalComponent } from './_shared/r-action-result-modal/r-action-result-modal.component';
import { RLevelDoneModalComponent } from './_shared/r-level-done-modal/r-level-done-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RInfoModalComponent } from './_shared/r-info-modal/r-info-modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    MainMenuComponent,
    NotFoundComponent,
    OnboardingComponent,
    SplashscreenComponent,
    CustomizationsListComponent,
    SettingsComponent,
    IconsGridComponent,
    RActionModalComponent,
    PreviewGameComponent,
    PreviewGridComponent,
    RActionResultModalComponent,
    RLevelDoneModalComponent,
    RInfoModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgbModule,
    MonacoEditorModule.forRoot()

  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NgbActiveModal
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
