import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { keyNames } from '../_models/utils';

@Component({
  selector: 'app-customizations-list',
  templateUrl: './customizations-list.component.html',
  styleUrls: ['./customizations-list.component.scss']
})
export class CustomizationsListComponent implements OnInit {

  @HostListener('window:keydown', ['$event.code']) 
  public keyPressHandler(code: string) {
    switch(code) {
      case keyNames.ENTER: 
        return this.router.navigate([this.customizationsGamesList[this.currentItem].route]);
      case keyNames.DOWN: 
        this.currentItem++;
        if (this.currentItem == this.customizationsGamesList.length) { this.currentItem = 0}
        return;
      case keyNames.UP: 
        this.currentItem--;
        if (this.currentItem == -1) { this.currentItem = this.customizationsGamesList.length-1}
        return;
      case keyNames.BACKSPACE:
          return this.router.navigate(['/main-menu']);  
      default:
        return;
    }
  }


  constructor(private router: Router) { }

  public currentItem = -1;
  public customizationsGamesList = [
    {
      name: 'Snake Game',
      imagePath: 'snake.png',
      customizations: '9',
      route: '/my-customizations'
    },
    {
      name: 'Pacman Game',
      imagePath: 'pacman.png',
      customizations: '4',
      route: '/my-customizations'
    }
  ];

  ngOnInit(): void {
  }

}
