import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-r-info-modal',
  templateUrl: './r-info-modal.component.html',
  styleUrls: ['./r-info-modal.component.scss']
})
export class RInfoModalComponent implements OnInit {

  @Input() modalTitle: string = "Hi, I am Robert, your helper console...";
  @Input() modalP1: string;
  @Input() modalP2: string;
  @Input() modalP3: string;
  @Input() modalBtnText: string = "Got it";
  @Input() modalImgPath: string = "../../assets/img/website/r-happy.png";
  username: string;

  constructor(
    public activeModal: NgbActiveModal,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.username = this.accountService.accountValue.username;
  }

  close() {
    this.activeModal.close();
  }
}
