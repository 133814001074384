<div class="modal-content pxl-border border-blue p-3">
  <div class="modal-header">
    <img src="{{modalImgPath}}" class="position-absolute zindex-1 w-20 r-game" />
    <h1 class="modal-title mt-0" id="exampleModalLongTitle">{{modalTitle}}</h1>
  </div>

  <div class="modal-body">
    <p>{{modalP1}}</p>
    <p>{{modalP2}}</p>
    <p>{{modalP3}}</p>
    <p>Good luck, {{username}}!</p>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn-retro btn-orange pxl-border border-orange"
      (click)="close()">{{modalBtnText}}</button>
  </div>
</div>
