import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";

@Component({
  selector: 'app-preview-game',
  templateUrl: './preview-game.component.html',
  styleUrls: ['./preview-game.component.scss']
})
export class PreviewGameComponent implements OnInit {

  @Input() htmlModel;
  @Input() cssModel;
  @Input() jsModel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    var body = $('#play_iframe').contents().find('body');
    body.html(this.htmlModel);

    var cssLink = '<style>' + this.cssModel + '</style>';
    var head = $('#play_iframe').contents().find('head');
    head.html('');
    head.append(cssLink);

    var js = '<script>' +
      `if (typeof(jQuery) == "undefined") {
        var iframeBody = document.getElementsByTagName("body")[0];
        var jQuery = function (selector) { return parent.jQuery(selector, iframeBody); };
        var $ = jQuery;
    }`
      + this.jsModel + '</script>';

    $('#play_iframe').contents().find('body').append(js).end();

    $('#play_iframe').focus();
  }

  close() {
    this.activeModal.close();
  }

}
