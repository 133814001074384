<div class="bg-synth">
  <app-icons-grid></app-icons-grid>
  <div class="position-relative container-fluid h-100 d-flex flex-column">
    <div class="row justify-content-center flex-1">
      <div class="col-12 col-lg-12 text-center">
        <h1 class="font-terminal text-uppercase text-blue pt-5 text-lg glitch">MAIN MENU</h1>
      </div>
    </div>

    <div class="row justify-content-center flex-2">
      <ul class="list-unstyled text-center">
        <li
          class="py-2 list-item main-menu"
          *ngFor="let item of menuItems; let i = index"
          [ngClass]="{active: currentItem === i}"
          (mouseover)="currentItem = i">
          <span class="h4 text-blue font-terminal">&#62;&nbsp;</span>
          <a class="h2 font-terminal text-blue text-lg" [routerLink]="item.route">{{item.name}}</a>
          <span class="h4 text-blue font-terminal">&nbsp;&#60;</span>
        </li>
      </ul>
    </div>

    <!-- helper text -->
    <div class="row">
      <div class="col-12 col-lg-6 offset-lg-3 text-center mb-3">
        <p>
          <span class="text-helper px-1">
            <svg width="20" height="20" fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	          viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve"><g><g>
              <path fill="#ffffff" d="M240,0C156.091,0.093,88.093,68.091,88,152v176c0,83.947,68.053,152,152,152s152-68.053,152-152V152
                  C391.907,68.091,323.909,0.093,240,0z M256,80v48c0,8.837-7.163,16-16,16s-16-7.163-16-16V80c0-8.837,7.163-16,16-16
                  S256,71.163,256,80z M104,152c0.119-71.926,56.2-131.333,128-135.592v32.728C217.915,52.773,208.055,65.453,208,80v48
                  c0.055,14.547,9.915,27.227,24,30.864V192H104V152z M376,328c-0.084,75.076-60.924,135.916-136,136
                  c-75.076-0.084-135.916-60.924-136-136V208h272V328z M376,192H248v-33.136c14.086-3.637,23.945-16.317,24-30.864V80
                  c-0.055-14.547-9.914-27.227-24-30.864V16.408C319.8,20.667,375.881,80.074,376,152V192z"/>
              </g>
            </g>
            </svg>
          </span>
          <span class="font-terminal text-white text-helper">Use mouse or</span>
          <span class="text-helper px-2">
            <svg height="25" viewBox="0 0 60 46" width="25" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" fill="#ffffff" fill-rule="evenodd">
              <g id="039---Cursor-Keyboard" fill="rgb(0,0,0)" fill-rule="nonzero">
                <path id="Shape" fill="#ffffff" d="m54 22h-9c-2.0123062.001472-3.889878 1.0116057-5 2.69-.4452257-.6702092-1.0197908-1.2447743-1.69-1.69 1.6783943-1.110122 2.688528-2.9876938 2.69-5v-12c-.0033074-3.31233757-2.6876624-5.99669262-6-6h-10c-3.3123376.00330738-5.9966926 2.68766243-6 6v12c.001472 2.0123062 1.0116057 3.889878 2.69 5-.6702092.4452257-1.2447743 1.0197908-1.69 1.69-1.110122-1.6783943-2.9876938-2.688528-5-2.69h-9c-3.31233757.0033074-5.99669262 2.6876624-6 6v12c.00330738 3.3123376 2.68766243 5.9966926 6 6h9c2.0123062-.001472 3.889878-1.0116057 5-2.69 1.110122 1.6783943 2.9876938 2.688528 5 2.69h10c2.0123062-.001472 3.889878-1.0116057 5-2.69 1.110122 1.6783943 2.9876938 2.688528 5 2.69h9c3.3123376-.0033074 5.9966926-2.6876624 6-6v-12c-.0033074-3.3123376-2.6876624-5.9966926-6-6zm-33-4v-12c0-2.209139 1.790861-4 4-4h10c2.209139 0 4 1.790861 4 4v12c0 2.209139-1.790861 4-4 4h-10c-2.209139 0-4-1.790861-4-4zm-6 26h-9c-2.209139 0-4-1.790861-4-4v-12c0-2.209139 1.790861-4 4-4h9c2.209139 0 4 1.790861 4 4v12c0 2.209139-1.790861 4-4 4zm20 0h-10c-2.209139 0-4-1.790861-4-4v-12c0-2.209139 1.790861-4 4-4h10c2.209139 0 4 1.790861 4 4v12c0 2.209139-1.790861 4-4 4zm23-4c0 2.209139-1.790861 4-4 4h-9c-2.209139 0-4-1.790861-4-4v-12c0-2.209139 1.790861-4 4-4h9c2.209139 0 4 1.790861 4 4z"/>
                <path id="Shape" fill="#ffffff" d="m32.343 9.328c-.5693051-.71193782-1.4314279-1.12640693-2.343-1.12640693s-1.7736949.41446911-2.343 1.12640693l-2.438 3.047c-.223291.2790244-.2807627.655978-.1507659.9888659.1299967.3328879.4277123.5711365.781.625.3532878.0538634.7084749-.0848414.9317659-.3638659l2.439-3.048c.1954793-.2271833.4802928-.357851.78-.357851s.5845207.1306677.78.357851l2.439 3.048c.223291.2790244.5784781.4177293.9317659.3638659.3532877-.0538635.6510033-.2921121.781-.625s.0725251-.7098415-.1507659-.9888659z"/>
                <path id="Shape" fill="#ffffff" d="m33.219 32.577-2.438 3.048c-.1956664.2272242-.4806397.357899-.7805.357899s-.5848336-.1306748-.7805-.357899l-2.439-3.048c-.3460579-.4272994-.9721792-.4950446-1.4016245-.1516534-.4294454.3433913-.5010869.9690788-.1603755 1.4006534l2.439 3.049c.5693197.7115667 1.4312086 1.1257818 2.3425 1.1257818s1.7731803-.4142151 2.3425-1.1257818l2.438-3.048c.3407114-.4315746.2690699-1.0572621-.1603755-1.4006534-.4294453-.3433912-1.0555666-.275646-1.4016245.1516534z"/>
                <path id="Shape" fill="#ffffff" d="m11.6 31.8c.2858125-.2143593.4355548-.5649147.3928204-.9196153-.0427344-.3547005-.2714531-.6596588-.6-.8s-.7070079-.0947441-.9928204.1196153l-3.21 2.438c-.74616698.5540698-1.18605597 1.4286139-1.18605597 2.358s.43988899 1.8039302 1.18605597 2.358l3.21 2.446c.1726129.1307418.3834646.2010257.6.2.4304304 0 .8125693-.2754301.9486833-.6837722.136114-.4083422-.004339-.8579696-.3486833-1.1162278l-3.2-2.435c-.24791032-.1758991-.39521183-.4610263-.39521183-.765s.14730151-.5891009.39521183-.765z"/>
                <path id="Shape" fill="#ffffff" d="m52.814 32.642-3.214-2.442c-.2858125-.2143594-.6642735-.2599565-.9928203-.1196153-.3285469.1403412-.5572657.4452995-.6000001.8-.0427344.3547006.1070079.705256.3928204.9196153l3.209 2.439c.2479103.1758991.3952118.4610263.3952118.765s-.1473015.5891009-.3952118.765l-3.209 2.431c-.3443443.2582582-.4847973.7078856-.3486833 1.1162278.136114.4083421.5182529.6837722.9486833.6837722.2165354.0010257.4273871-.0692582.6-.2l3.21-2.438c.746167-.5540698 1.186056-1.4286139 1.186056-2.358s-.439889-1.8039302-1.186056-2.358z"/></g></g>
              </svg>
          </span>
          <span class="font-terminal text-white text-helper">arrow keys to select item</span>
        </p>
      </div>
    </div>
  </div>
</div>
