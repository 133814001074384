import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preview-grid',
  templateUrl: './preview-grid.component.html',
  styleUrls: ['./preview-grid.component.scss']
})
export class PreviewGridComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
