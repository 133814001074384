import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { CustomizationsListComponent } from './customizations-list/customizations-list.component';
import { SplashscreenComponent } from './splashscreen/splashscreen.component';
import { SettingsComponent } from './settings/settings.component';
import { PreviewGameComponent } from './preview-game/preview-game.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./website/website.module').then(m => m.WebsiteModule),
  },
  // {
  //   path: 'onboarding',
  //   component: OnboardingComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'main-menu',
  //   component: MainMenuComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'customizations-list',
  //   component: CustomizationsListComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'splashscreen',
  //   component: SplashscreenComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'settings',
  //   component: SettingsComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'preview-game',
  //   component: PreviewGameComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'games-list',
  //   loadChildren: () => import('./games-list/games-list.module').then(m => m.GamesListModule),
  //   data: { roles: [Role.Admin, Role.User] },
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: 'my-customizations',
  //   loadChildren: () => import('./my-customizations/my-customizations.module').then(m => m.MyCustomizationsModule),
  //   data: { roles: [Role.Admin, Role.User] },
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  //   data: { roles: [Role.Admin, Role.User] },
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: 'arcade-room',
  //   loadChildren: () => import('./arcade-room/arcade-room.module').then(m => m.ArcadeRoomModule),
  //   data: { roles: [Role.Admin, Role.User] },
  //   canLoad: [AuthGuard]
  // },
  // {
  //   path: 'shop',
  //   loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
  //   data: { roles: [Role.Admin, Role.User] },
  //   canLoad: [AuthGuard]
  // },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
