import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { keyNames } from '../_models/utils';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  public currentItem = -1;

  @HostListener('window:keydown', ['$event.code']) 
  public keyPressHandler(code: string) {
    switch(code) {
      case keyNames.ENTER: 
        return this.router.navigate([this.menuItems[this.currentItem].route]);
      case keyNames.DOWN: 
        this.currentItem++;
        if (this.currentItem == this.menuItems.length) { this.currentItem = 0}
        return;
      case keyNames.UP: 
        this.currentItem--;
        if (this.currentItem == -1) { this.currentItem = this.menuItems.length-1}
        return;
      case keyNames.ESC:
        return this.router.navigate(['/']);
      default:
        return;
    }
  }
  

  public menuItems = [
    {
      name: "Games List",
      route: "/games-list"
    },
    {
      name: "My Customizations",
      route: "/customizations-list"
    },
    {
      name: "Arcade Room",
      route: "/arcade-room"
    },
    {
      name: "Profile",
      route: "/profile"
    },
    // {
    //   name: "Shop",
    //   route: "/shop"
    // },
    {
      name: "Settings",
      route: "/settings"
    },
    {
      name: "Exit",
      route: "/"
    }
]
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  showLoader() {
    document.getElementById("loader").style.display = "block";
  }
}
