<div class="position-absolute w-100 h-100 game-grid t-0 py-3">
    <div class="game-grid__player-info align-self-center">
        <p class="h3 font-terminal text-blue mb-0 text-end">Dee19</p>
        <p class="h4 font-terminal text-blue mb-0 text-end">201456</p>
    </div>
    <div class="game-grid__player justify-self-center">
        <img src="../../assets/img/website/bit-neutral.png" />
    </div>
    <div class="game-grid__r mt-4 justify-self-center">
      <a data-bs-toggle="modal" data-bs-target="#rInfoModal">
        <img src="../../assets/img/website/r-happy.png" />
        <p class="h5 font-terminal text-blue mb-0 text-center mt-2">Robert</p>
      </a>
    </div>
  </div>

<!-- <app-coddex></app-coddex> -->

