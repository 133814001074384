import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { keyNames } from '../_models/utils';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @HostListener('window:keydown', ['$event.code']) 
  public keyPressHandler(code: string) {
    switch(code) {
      case keyNames.ENTER: 
        return this.router.navigate(['/splashscreen']);
      case keyNames.ESC:
        return this.router.navigate(['/main-menu']);
      default:
        return;
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
