<div class="bg-synth">
  <div class="container d-flex flex-column px-5">
    <div class="row mt-5">
      <!-- back button -->
      <div class="col-12 col-lg-2 zindex-1">
        <span class="btn btn-arrow font-terminal text-blue pa-0">&#60;</span>
        <button type="button" class="btn btn-link font-terminal text-blue px-0" (click)="close()">back</button>
      </div>
      <!-- page title -->
      <div class="col-12 col-lg-8">
        <h1 class="font-terminal text-uppercase text-blue glitch text-center mb-0">Your game</h1>
        <p class="font-terminal text-uppercase text-blue text-center pt-0">LEVEL 1 (PREVIEW MODE)</p>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 col-lg-12 h-60vh px-0 pxl-border border-dark-purple" style="background-color: #391377;">
        <iframe id="play_iframe" class="w-100 h-100" #playIframe></iframe>
      </div>
    </div>

  </div>

  <!-- <app-preview-grid></app-preview-grid> -->

</div>
