import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { keyNames } from '../_models/utils';

@Component({
  selector: 'app-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss']
})
export class SplashscreenComponent implements OnInit {

  @HostListener('window:keydown', ['$event.code']) 
  public keyPressHandler(code: string) {
    switch(code) {
      case keyNames.ENTER: 
        return this.router.navigate(['/main-menu']);
      case keyNames.ESC:
        return this.router.navigate(['/']);
      default:
        return;
    }
  }

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
